// TODO rename
export const LOAD_MCMENU_TREE = 'LOAD_MCMENU_TREE';
export const CHANGE_MCMENU_TREE_ITEM = 'CHANGE_MCMENU_TREE_ITEM';
export const RESET_MCMENU_TREE_ITEM = 'RESET_MCMENU_TREE_ITEM';
export const CHANGE_MCMENU_SCROLL_PAGE = 'CHANGE_MCMENU_SCROLL_PAGE';
export const RESET_MCMENU_SCROLL_PAGE = 'RESET_MCMENU_SCROLL_PAGE';
export const GET_CONFIGURATOR_FLAG = 'GET_CONFIGURATOR_FLAG';
export const GET_CONFIGURATOR_FLAG_CHAIR = 'GET_CONFIGURATOR_FLAG_CHAIR';
export const GET_CONFIGURATOR_FLAG_SOFAS = 'GET_CONFIGURATOR_FLAG_SOFAS';
export const GET_CONFIGURATOR_FLAG_MATTRESSES = 'GET_CONFIGURATOR_FLAG_MATTRESSES';
export const GET_BF_CONFIGURATOR_FLAG = 'GET_BF_CONFIGURATOR_FLAG';
export const COLLECTIONS_MENU = 'COLLECTIONS_MENU';

// TODO rename
/** @namespace Pwa/Store/McMenu/Action/loadMcMenu */
export const loadMcMenu = (payload) => ({
    type: LOAD_MCMENU_TREE,
    payload
});

/** @namespace Pwa/Store/McMenu/Action/changeCurrentMcMenuItem */
export const changeCurrentMcMenuItem = (path) => ({
    type: CHANGE_MCMENU_TREE_ITEM,
    path
});

/** @namespace Pwa/Store/McMenu/Action/getFlagConfigurator */
export const getFlagConfigurator = (flagConfigurator) => ({
    type: GET_CONFIGURATOR_FLAG,
    flagConfigurator
});

// export const getBFFlagConfigurator = (flagBFConfigurator) => ({
//     type: GET_BF_CONFIGURATOR_FLAG,
//     flagBFConfigurator
// });

/** @namespace Pwa/Store/McMenu/Action/resetCurrentMcMenuItem */
export const resetCurrentMcMenuItem = () => ({
    type: RESET_MCMENU_TREE_ITEM
});

/** @namespace Pwa/Store/McMenu/Action/setMcMenuPageScroll */
export const setMcMenuPageScroll = (amount) => ({
    type: CHANGE_MCMENU_SCROLL_PAGE,
    amount
});

/** @namespace Pwa/Store/McMenu/Action/resetMcMenuPageScroll */
export const resetMcMenuPageScroll = () => ({
    type: RESET_MCMENU_SCROLL_PAGE
});

/** @namespace Pwa/Store/McMenu/Action/getCollections */
export const getCollections = (collections) => ({
    type: COLLECTIONS_MENU,
    collections
});

/** @namespace Pwa/Store/McMenu/Action/getFlagConfiguratorChair */
export const getFlagConfiguratorChair = (flagConfiguratorChair) => ({
    type: GET_CONFIGURATOR_FLAG_CHAIR,
    flagConfiguratorChair
});

/** @namespace Pwa/Store/McMenu/Action/getFlagConfiguratorSofas */
export const getFlagConfiguratorSofas = (flagConfiguratorSofas) => ({
    type: GET_CONFIGURATOR_FLAG_SOFAS,
    flagConfiguratorSofas
});

/** @namespace Pwa/Store/McMenu/Action/getFlagConfiguratorMattress */
export const getFlagConfiguratorMattress = (flagConfiguratorMattresses) => ({
    type: GET_CONFIGURATOR_FLAG_MATTRESSES,
    flagConfiguratorMattresses
});
